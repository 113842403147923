@import "base.scss";
@import "sidebar.scss";
@import "setupview.scss";
@import "toolbar.scss";
@import "forms.scss";
@import "plone.scss";
@import "loader.scss";

html, body  { height: 100%; }

/* Headers */
h1, .h1, h2, .h2, h3, .h3 { font-weight: 300; }
h1, .h1 { font-size: 2rem; }
h2, .h2 { font-size: 1.85rem; }

/* Remove dotted outline */
a { outline: 0; }

/* SVG icon styles */
i.hazardous-icon svg { fill: $danger; }


/* inline delete button for WS attachments */
img.deleteAttachmentButton {
    cursor: pointer;
    width: 16px;
}

/* Main content */
div.container-fluid {
  padding-left:30px;
}

/* Some spacing for the view title */
#viewlet-listing-table-title {
  margin-bottom:10px;
  h1 {
    margin:0 10px 0;
  }
}

/* Remove bootstrap's default transition for buttons */
.btn, .btn:hover {
  /*text-decoration: none;
  transition: none;*/
}

/* Notification Panel
   Currently only shown when changing the instrument in WSs */
#panel-notification {
    top: 50%;
    left: 50%;
    position: fixed;
    z-index: 1000;
}

#panel-notification {
    .error-notification-item {
        @extend .border;
        @extend .border-warning;
        @extend .card;
        @extend .py-3;
        @extend .px-4;
    }
    .succeed-notification-item {
        @extend .border;
        @extend .border-success;
        @extend .card;
        @extend .py-3;
        @extend .px-4;
    }
}

/* Priority icons in listings */
.priority-ico {
    @extend .fa;
    span {
        @extend .d-none;
    }
    &.priority-1 {
        @extend .fa-arrow-up;
        @extend .text-danger;
    }
    &.priority-2 {
        @extend .fa-arrow-up;
        @extend .text-warning;
    }
    &.priority-3 {
        @extend .fa-arrow-up;
        @extend .text-secondary;
    }
    &.priority-4 {
        @extend .fa-arrow-down;
        @extend .text-info;
    }
    &.priority-5 {
        @extend .fa-arrow-down;
        @extend .text-success;
    }
}

/* Footer right icons */
#senaite-footer .nav-pills li a.nav-link {
    padding-top: 0rem;
    padding-bottom: 0rem;
}

/** Footer colophon Powered by.. **/
#senaite-colophon {
    font-size: 0.9rem;
}
